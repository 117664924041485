import React from "react"
import { graphql, Link } from "gatsby"
import SiteContainer from "../../components/site-container";
import { Helmet } from "react-helmet";
import PostContent from "../../components/PostContent";

export default function Blog({ data, location }) {
  return (
    <SiteContainer location={location} >
      <Helmet>
        <title>Firstparty News</title>
        <meta name="description" content="Firstparty News" />
        <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
      </Helmet>

      <section className="py-10 py-md-4 mb-10 bg-black">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7 text-center">
              <h3 className="display-4 fw-bold text-white">
                Firstparty Newsroom
              </h3>
              <p className="lead mb-0 text-white-75">
                Keep up to date with what we're working on!
              </p>
            </div>
          </div>
        </div>
      </section>

      {data.allWpPost.edges.map(({ node }) => (
        <PostContent post={node} link={true} />
      ))}
    </SiteContainer>
  )
}
export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          excerpt
          content
          slug
          seo {
            fullHead
            title
          }
          date
          author {
            node {
              name
              avatar {
                  default
                  extraAttr
                  forceDefault
                  foundAvatar
                  height
                  rating
                  scheme
                  size
                  url
                  width
                }
            }
          }
        }
      }
    }
  }
`